<template>
  <div class="bucket-add">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基础设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/setup/bucket' }">存储桶管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加存储桶</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="pageForm" :rules="pageRules" ref="pageRef" label-width="auto" class="page-form">
        <el-form-item label="存储桶名称" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入1-10位小写字母+数字的组合名称"></el-input>
        </el-form-item>
        <el-form-item label="存储桶备注" prop="mark">
          <el-input v-model="pageForm.mark" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">立即创建</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BucketAdd',
  data () {
    return {
      pageForm: {
        title: '',
        mark: ''
      },
      pageRules: {
        title: [
          { required: true, message: '请填写存储桶名称', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在 1 到 10 个英文加数字字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.pageRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/bucket', this.pageForm)
          if (res.code === 200) {
            this.$message.success(res.msg)
            await this.$router.push('/admin/setup/bucket')
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('表单验证失败')
          return false
        }
      })
    },
    back () {
      this.$router.push('/admin/setup/bucket')
    }
  }
}
</script>

<style scoped>
.page-content{
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
.page-form{
  width: 600px;
  margin-right: auto;
  margin-left: auto;
}
</style>
